import React from 'react';
import {animated, useSpring} from "react-spring";
import logo_pizza from "../../../img/images/logo-pizzav2.svg";
import ReactTooltip from "react-tooltip";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 3) / 20, 1.1];
const trans4 = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const V2PizzaCard = () => {

    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));

    return (
        <>
            <a data-tip data-arrow-color="#5C88E5" data-for='v2-pizza'  href="https://v2-pizza.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                <animated.div
                    className="card-second-section-desktop-3"
                    onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
                    onMouseLeave={() => set({xys: [0, 0, 1]})}
                    style={{
                        transform: props.xys.interpolate(trans4),
                        backgroundImage: `url(${logo_pizza})`,
                        backgroundSize: "60%",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "white"
                    }}
                />
            </a>
            <ReactTooltip delayShow={300} className="tooltip-desk-item" id="v2-pizza" aria-haspopup='true' place="bottom" data-multiline={true} role='example'>
                <p className="text-center h4">
                    <span>Site e-commerce type Livraison et Click and Collect</span><br/>
                </p>
                <p className="h6">Site permettant d'administrer le contenu comme la carte, les catégories de la carte, la bannière d'accueil mais aussi la gestion des clients,
                    des modes de livraison et leur prix, des commandes et des livraisons. Espace membre avec commande et paiement en ligne sécurisé, gestion de son compte.
                    Un véritable outil de communication et de gestion sécurisé<br/></p>
            </ReactTooltip>
        </>
    );
};

export default V2PizzaCard;

import React from 'react';
import {Row, Col} from "react-bootstrap";


import './SecondSectionMobile.css';
import main_restaurant from "../../../img/logo/main_restaurant.jpg";
import RestaurantGalleryMobile from "../../RestaurantGallery/RestaurantGalleryMobile/RestaurantGalleryMobile";

const SecondSectionMobile = () => {

    return (
        <>
            <div className="container-fluid mt-3 mb-3">
                <Row>
                    <Col className="center" xs={12}>
                        <img className="img-fluid w-75" alt="restaurant" src={main_restaurant} />
                    </Col>
                </Row>
                <Row className="">
                    <Col className="center mt-3 mb-3" xs={12}>
                        <h1 className="h2 text-center">Les projets de site<br/>vitrine</h1>
                    </Col>
                    <Col className="gallery-mobile-section" xs={12}>
                        <RestaurantGalleryMobile />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default SecondSectionMobile;

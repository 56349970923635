import React, {Component} from 'react';
import {Row,Col} from 'react-bootstrap';

import './ContactDesktop.css';
import header_desktop from "../../../img/background/contact-bg.svg";
import ContactForm from "../ContactForm/ContactForm";

class ContactDesktop extends Component {
    render() {
        return (
            <>
                <Row>
                    <Col className="contact-top-desktop" style={{
                        backgroundImage: `url(${header_desktop})`,
                        backgroundSize: "cover",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                    }} xs={12}>

                    </Col>
                    <Col className="contact-desktop-section" xs={12}>
                        <Row>
                            <Col className="mt-n5 mb-5" xs={{span:8, offset:3}}>
                                <p className="h1 text-white center"><strong>On reste en contact...</strong></p>
                            </Col>
                            <Col className="center" xs={{span:8, offset:3}}>
                                <p className="text-white text-center">
                                    Si vous préférez me contacter directement par téléphone, vous pouvez me joindre au<br/> <strong>06.50.66.43.38</strong><br/>
                                </p>
                            </Col>
                            <Col className="mb-5 mt-3" xs={{span:8, offset:3}}>
                               <ContactForm />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ContactDesktop;
import React from 'react';
import {Row, Col} from "react-bootstrap";
import {isTablet} from 'react-device-detect';

import '../../RestaurantGallery/RestaurantGalleryDesktop/RestaurantGalleryDesktop.css';

import V2PizzaCard from "./V2PizzaCard";
import EuratechCard from "./EuratechCard";

const RestaurantGalleryDesktop = () => {

    return (
        <>
            <div>
                <Row className="center">
                    {
                        isTablet &&
                        <Col className="mobile-vitrine-presentation mt-5" xs={12}>
                            <p className="text-center mb-2 h6">
                                Site permettant d'administrer le contenu comme la carte, les catégories de la carte, la bannière d'accueil mais aussi la gestion des clients,
                                des modes de livraison et leur prix, des commandes et des livraisons. Espace membre avec commande et paiement en ligne sécurisé, gestion de son compte. Formulaire
                                de contact relié à une boîte mail, la gestion des envois d'emails à vos clients avec une mise en page, la possibilité de créer une newsletter.<br/>
                                Un véritable outil de communication et de gestion sécurisé<br/>
                            </p>
                        </Col>
                    }
                    <Col xs={5} xl={6} className="center mt-5">
                        <V2PizzaCard />
                    </Col>
                    <Col xs={5} xl={6} className="center mt-5">
                        <EuratechCard />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default RestaurantGalleryDesktop;

import React from 'react';
import {animated, useSpring} from "react-spring";
import logo_artisan from "../../../img/images/logo-ab-ramonage.svg";
import ReactTooltip from "react-tooltip";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 3) / 20, 1.1];
const trans1 = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const PizzaCard = () => {

    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));

    return (
        <>
            <a data-tip data-arrow-color="#5C88E5" data-for='ramonage'  href="https://abramonage.com" rel="noopener noreferrer" target="_blank">
                <animated.div
                    className="card-second-section-desktop-1"
                    onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
                    onMouseLeave={() => set({xys: [0, 0, 1]})}
                    style={{
                        transform: props.xys.interpolate(trans1),
                        backgroundImage: `url(${logo_artisan})`,
                        backgroundSize: "45%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "white"
                    }}
                />
            </a>
            <ReactTooltip delayShow={300} className="tooltip-desk-item" id="ramonage" aria-haspopup='true' place="right" data-multiline={true} role='example'>
                <p className="text-center h4">
                    <span>Site vitrine type service</span><br/>
                </p>
                <p className="h6">Site permettant d'afficher le contenu souhaité, comme les services proposés, les horaires, une présentation de l'entreprise.
                    Vous bénéficiez également d'un formulaire de contact sécurisé et relié à votre
                    boîte mail, vous permettant de rester en contact avec vos clients ou d'éventuels partenaires. Deux versions distinctes mobile et ordinateur, la version
                    mobile possèdant un bouton sur l'accueil permettant de vous appeler directement pour vous contacter.<br/></p>
            </ReactTooltip>
        </>
    );
};

export default PizzaCard;

import React from 'react';
import {Row, Col} from "react-bootstrap";
import { useSpring, animated } from 'react-spring';
import { motion } from "framer-motion";

import './FirstSectionDesktop.css';

import card_desk_logo from '../../../img/logo/logo-2.svg';

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1];
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;


const FirstSectionDesktop = () => {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));

    return (
        <>
            <div className="container-fluid p-0">
                <Row className="first-section-desktop">
                    <Col className="presentation-desktop-section mb-5 center" xs={12}>
                        <div className="container">
                            <p className="presentation-desktop text-center center text-white">
                                Ces projets sont des versions de démonstration réalisées dans le but de proposer des solutions
                                web aux commerçants de ma région. Je peux prendre en charge la conception, la réalisation de plateforme
                                regroupant les sites des commerçants. Mais également de réaliser leurs sites que ce soit des sites vitrines
                                ou e-commerce et donc répondant à tous les budgets. Fort d'une expérience commerciale de quinze ans je souhaite
                                mettre à contribution celle-ci aux service des commerçants et les aider à se faire une place sur internet.
                            </p>
                        </div>
                    </Col>
                    <Col className="my-auto skills-section text-white" xs={4}>
                        <Row>
                            <Col className="center" xs={12}>
                                <motion.div
                                    animate={{
                                        scale: [1, 1.35, 1.35, 1, 1],
                                        rotate: [0, 0, 270, 270, 0],
                                        borderRadius: ["20%", "20%", "50%", "50%", "20%"]
                                    }}
                                    transition={{
                                        duration: 4,
                                        ease: "easeInOut",
                                        times: [0, 0.2, 0.5, 0.8, 1],
                                        loop: Infinity,
                                        repeatDelay: 1
                                    }}
                                >
                                    <h2 className="font-weight-bold h4">Site vitrine</h2>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center list-desk-content" xs={12}>
                                <div className="text-center">
                                    <p>Site permettant de présenter ses services</p><br/>
                                    <p>Comporte un formulaire de contact</p><br/>
                                    <p>Sur mobile possibilité d'ajout d'un bouton lançant un appel pour commander</p><br/>
                                    <p>Petit budget, temps de réalisation rapide</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} className="center">
                        <animated.div
                            className="card-first-section-desktop"
                            onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
                            onMouseLeave={() => set({xys: [0, 0, 1]})}
                            style={{
                                transform: props.xys.interpolate(trans),
                                backgroundImage: `url(${card_desk_logo})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        />
                    </Col>
                    <Col className="my-auto text-white" xs={4}>
                        <Row >
                            <Col className="center" xs={12}>
                                <motion.div
                                    animate={{
                                        scale: [1, 1.35, 1.35, 1, 1],
                                        rotate: [0, 0, 270, 270, 0],
                                        borderRadius: ["20%", "20%", "50%", "50%", "20%"]
                                    }}
                                    transition={{
                                        duration: 4,
                                        ease: "easeInOut",
                                        times: [0, 0.2, 0.5, 0.8, 1],
                                        loop: Infinity,
                                        repeatDelay: 1
                                    }}
                                >
                                    <h2 className="font-weight-bold h4">Site e-commerce</h2>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center list-desk-content" xs={12}>
                                <div className="text-center">
                                    <p>Permet au client de commander et payer</p><br/>
                                    <p>Contrôler le contenu du site (produits proposés, informations)</p><br/>
                                    <p>Améliorer sa relation client ainsi que son CA</p><br/>
                                    <p>Un réél outil de travail au quotidien, votre boutique 24h sur 24, 7 jours sur 7 ouverte</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default FirstSectionDesktop;

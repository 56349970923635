import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import ItemsCarousel from 'react-items-carousel';

import logo_friterie from "../../../img/images/logo-friterie.svg";
import logo_pizza from "../../../img/images/logo-pizza.svg";
import logo_kebab from "../../../img/images/logo-kebab.svg";
import logo_ramonage from "../../../img/images/logo-ab-ramonage.svg";
import left_chevron from '../../../img/icones/left-arrow.svg';
import right_chevron from '../../../img/icones/right-arrow.svg';


import './RestaurantGalleryMobile.css';



const RestaurantGalleryMobile = () => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 30;
    const isMobile = window.innerWidth <= 480;

    return (
        <>
            <Row className="container-fluid mb-5 p-0">
                <Col style={{ padding: `0 ${chevronWidth}px` }}>
                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={3}
                        gutter={20}
                        leftChevron={<button className="btn-arrow"><img src={left_chevron} className="w-100" alt="flèche de déplacement vers la gauche"/></button>}
                        rightChevron={<button className="btn-arrow"><img src={right_chevron} className="w-100" alt="flèche de déplacement vers la droite"/></button>}
                        outsideChevron
                        chevronWidth={chevronWidth}
                    >
                        {isMobile ?
                            (<div className="center" style={{ height: 80, background: '#FFF' }}>
                                <a className="center" href="https://v1-friterie.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                                    <img className="img-fluid w-75" src={logo_friterie} alt="french fries friterie" />
                                </a>
                            </div>) : (<div className="center" style={{ height: 150, background: '#FFF' }}>
                                <a className="center" href="https://v1-friterie.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                                    <img className="img-fluid w-75" src={logo_friterie} alt="french fries friterie" />
                                </a>
                            </div>)

                        }
                        { isMobile ?
                            (
                                <div className="center" style={{ height: 80, background: '#FFF' }}>
                                    <a className="center" href="https://v1-pizza.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                                        <img className="img-fluid mb-3 w-75" src={logo_pizza} alt="lien pour site V1 pizza"/>
                                    </a>
                                </div>
                            ):(
                                <div className="center" style={{ height: 150, background: '#FFF' }}>
                                    <a className="center" href="https://v1-pizza.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                                        <img className="img-fluid mb-3 w-75" src={logo_pizza} alt="lien pour site V1 pizza"/>
                                    </a>
                                </div>
                            )
                        }
                        {
                            isMobile ?
                                (
                                    <div className="center" style={{ height: 80, background: '#FFF' }}>
                                        <a className="center" href="https://abramonage.com" rel="noopener noreferrer" target="_blank">
                                            <img className="img-fluid w-75" src={logo_ramonage} alt="lien pour site d'ab ramonage" />
                                        </a>
                                    </div>
                                ):(
                                    <div className="center" style={{ height: 150, background: '#FFF' }}>
                                        <a className="center" href="https://abramonage.com" rel="noopener noreferrer" target="_blank">
                                            <img className="img-fluid w-75" src={logo_ramonage} alt="lien pour site d'ab ramonage" />
                                        </a>
                                    </div>
                                )
                        }
                        {
                            isMobile ?
                                (
                                    <div className="center" style={{ height: 80, background: '#FFF' }}>
                                        <a className="center" href="https://v1-kebab.ea-studio.fr" rel="noopener noreferrer" target="_blank">
                                            <img className="img-fluid w-75" src={logo_kebab} alt="lien pour site V1 kebab" />
                                        </a>
                                    </div>
                                ):(
                                    <div className="center" style={{ height: 150, background: '#FFF' }}>
                                        <a className="center" href="https://v1-kebab.ea-studio.fr" rel="noopener noreferrer" target="_blank">
                                            <img className="img-fluid w-75" src={logo_kebab} alt="lien pour site V1 kebab" />
                                        </a>
                                    </div>
                                )
                        }
                    </ItemsCarousel>
                </Col>
                <Col className="mobile-vitrine-presentation" xs={12}>
                    <p className="text-center mb-2 h6">
                        Il permet d'afficher le contenu souhaité, comme les services et/ou produits proposés avec filtres de recherche, horaires, présentation de l'entreprise,
                        publications venant de réseaux sociaux. Vous bénéficiez également d'un formulaire de contact sécurisé et relié à votre
                        boîte mail. Il vous aide à rester en contact avec vos clients ou d'éventuels partenaires. Deux versions distinctes mobile et ordinateur: sur la version
                        mobile un bouton sur l'accueil permet de vous appeler directement pour commander.<br/>
                    </p>
                </Col>
            </Row>
        </>
    );
};

export default RestaurantGalleryMobile;

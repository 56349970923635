import './App.css';

import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import HubPage from "./components/HubPage/HubPage";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";

class App extends Component {


    render() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={HubPage} />
                    <Route exact path="*" component={NotFoundPage} />
                </Switch>
            </div>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
        </Router>
    );
  }
}

export default App;

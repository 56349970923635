import React from 'react';
import {Col, Row} from "react-bootstrap";

const Footer = () => {
    return (
        <footer style={{backgroundColor: "#5C88E5"}}>
            <Row>
                <Col>

                </Col>
                <Col>

                </Col>
                <Col xs={12} className="center">
                    <p className="text-center text-white mt-1 mb-0 company-sentence-desk">Made by <a target="_blank" rel="noopener noreferrer" className="text-white font-weight-bolder" href="https://e-kigaï-dev.fr">e-kigaï-dev </a>© 2020 - Tous droits réservés</p>
                </Col>
            </Row>
        </footer>
    );
};

export default Footer;

import React from 'react';
import {Col,NavbarBrand, Row} from 'react-bootstrap';
import { motion } from "framer-motion";
import { useSpring, animated } from 'react-spring';

import './HeaderDesktop.css';

import header_desktop from '../../../img/background/empty-bg.svg';
import logo_1 from '../../../img/logo/logo-header.svg';
import logo_2 from '../../../img/logo/logo-5.svg';
import logo_3 from '../../../img/logo/logo-3.svg';
import logo_4 from '../../../img/logo/logo-4.svg';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 200}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 - 200}px,${y / 6 - 180}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 8.5}px,${y / 9.5}px,0)`;



const HeaderDesktop = () => {
    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

    return (
        <>
            <header
                className="header-desktop"
                onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
                style={{
                    backgroundImage: `url(${header_desktop})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}>
                <Row className="desk-top-link">
                    <Col className="mt-3" xs={12}>
                        <Row>
                            <Col className="center" xs={6}>
                                <a className="btn nav-visit-link" target="_blank" rel="noopener noreferrer" href="https://e-kigaï-dev.fr">Visiter le site d'e-kigaï-dev</a>
                            </Col>
                            <Col className="center" xs={6}>
                                <a className="btn nav-visit-link" target="_blank" rel="noopener noreferrer" href="https://juliengrade.fr">Visiter le site de Julien Grade</a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="desk-first-pics-line">
                    <Col className="p-0" xs={6} xl={6}>
                        <animated.div style={{ transform: props.xy.interpolate(trans1) }} className="logo-header-desktop card1">
                            <img className="header-desktop-img w-50" src={logo_1} alt="Projets web"/>
                        </animated.div>
                    </Col>
                    <Col className="p-0" xs={{span: 2, offset: 3}} xl={{span: 2, offset: 3}}>
                        <animated.div style={{ transform: props.xy.interpolate(trans2) }} className="logo-header-desktop card2">
                            <img className="header-desktop-img w-100" src={logo_2} alt="Projets web"/>
                        </animated.div>
                    </Col>
                </Row>
                <Row className="desk-header-titles">
                    <Col className="title-desktop-section mx-auto" xs={4}>
                        <NavbarBrand className="main-desktop-title center">
                            <p>Présentation de projets</p>
                        </NavbarBrand>
                        <motion.div animate={{ scale: 1.6 }} transition={{ duration: 1.2 }} className="second-desktop-title center">
                            <p>Sites vitrines, sites e-commerce, solutions et applications web...</p>
                        </motion.div>
                    </Col>
                </Row>
                <Row className="desk-second-pics-line">
                    <Col className="p-0" xs={{offset:3, span: 4}} xl={{offset:2, span: 4}}>
                        <animated.div style={{ transform: props.xy.interpolate(trans3) }} className="logo-header-desktop card3">
                            <img className="header-desktop-img w-25 float-right" src={logo_3} alt="Projets web"/>
                        </animated.div>
                    </Col>
                    <Col className="p-0" xs={{offset:2, span: 2}} xl={{offset:4, span: 2}}>
                        <animated.div style={{ transform: props.xy.interpolate(trans4) }} className="logo-header-desktop card4">
                            <img className="header-desktop-img w-50" src={logo_4} alt="Projets web"/>
                        </animated.div>
                    </Col>
                </Row>
            </header>
        </>
    );
};

export default HeaderDesktop;

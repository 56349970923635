import React from 'react';
import {animated, useSpring} from "react-spring";
import logo_kebab from "../../../img/images/logo-kebab.png";
import ReactTooltip from "react-tooltip";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x/2 - window.innerWidth / 3) / 20, 1.1];
const trans2 = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const KebabCard = () => {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));

    return (
        <>
            <a data-tip data-arrow-color="#5C88E5" data-for='kebab'  href="https://v1-kebab.ea-studio.fr" rel="noopener noreferrer" target="_blank">
                <animated.div
                    className="card-second-section-desktop-2"
                    onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
                    onMouseLeave={() => set({xys: [0, 0, 1]})}
                    style={{
                        transform: props.xys.interpolate(trans2),
                        backgroundImage: `url(${logo_kebab})`,
                        backgroundSize: "80%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "white"
                    }}
                />
            </a>
            <ReactTooltip delayShow={300} className="tooltip-desk-item" id="kebab" aria-haspopup='true' place="left" data-multiline={true} role='example'>
                <p className="text-center h4">
                    <span>Site vitrine type kebab</span><br/>
                </p>
                <p className="h6">Site permettant d'afficher le contenu souhaité, comme la carte du restaurant, les horaires, une présentation de l'entreprise
                    mais aussi retrouver vos publications venant de réseaux sociaux. Vous bénéficiez également d'un formulaire de contact sécurisé et relié à votre
                    boîte mail, vous permettant de rester en contact avec vos clients ou d'éventuels partenaires. Deux versions distinctes mobile et ordinateur, la version
                    mobile possèdant un bouton sur l'accueil permettant de vous appeler directement pour commander.<br/></p>

            </ReactTooltip>
        </>
    );
};

export default KebabCard;

import React, {Component} from 'react';
import {Row, Col} from "react-bootstrap";

import './SecondSectionDesktop.css';

import vitrines from '../../../img/images/mobile-website.svg';
import restaurant from '../../../img/images/main_restaurant.svg';
import second_mini_restaurant from '../../../img/logo/second_mini_restaurant.jpg';
import RestaurantGalleryDesktop from "../../RestaurantGallery/RestaurantGalleryDesktop/RestaurantGalleryDesktop";


class SecondSectionDesktop extends Component {
    render() {
        return (
            <>
                <div className="desktop-second-section">
                    <Row>
                        <Col className="center" xs={12}>
                            <img className="img-fluid w-50" alt="restaurant" src={vitrines} />
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="center mt-3 mb-5" xs={12}>
                            <h1>Les projets de site vitrines</h1>
                        </Col>
                        <Col className="gallery-desktop-section container" xs={8}>
                            <RestaurantGalleryDesktop />
                        </Col>
                    </Row>
                    <Row className="bottom-pics">
                        <Col className="center" xs={12}>
                            <img className="img-fluid w-50" alt="table de restaurant" src={restaurant} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default SecondSectionDesktop;
import React from 'react';

import './HeaderMobile.css';

import header_mobile from '../../../img/background/header_mobile.svg';
import {Col,NavbarBrand, Row} from "react-bootstrap";
import {motion} from "framer-motion";
import logo_1 from "../../../img/logo/logo-header.svg";
import {animated, useSpring} from "react-spring";
import logo_4 from "../../../img/logo/logo-4.svg";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;


const HeaderMobile = () => {
    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

    return (
        <>
            <header className="header-mobile"
                    onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
                    style={{
                        backgroundImage: `url(${header_mobile})`,
                        backgroundSize: "cover",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                    }}>
                <Row>
                    <Col className="mt-3 p-0" xs={12}>
                        <Row>
                            <Col className="center p-0" xs={6}>
                                <a className="btn nav-visit-link" target="_blank" rel="noopener noreferrer" href="https://ewa-design.fr"><small>Visiter le site d'e-kigaï-dev</small></a>
                            </Col>
                            <Col className="center p-0" xs={6}>
                                <a className="btn nav-visit-link" target="_blank" rel="noopener noreferrer" href="https://e-kigaï-dev.fr"><small>Visiter le site de Julien Grade</small></a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="center">
                    <Col className="center" xs={10}>
                        <NavbarBrand className="main-mobile-title center mt-5 mr-0">
                            <h1 className="main-mobile-title-content text-center mx-auto"><strong>Présentation de projets</strong></h1>
                        </NavbarBrand>
                    </Col>
                    <Col className="" xs={8}>
                        <motion.div animate={{ scale: 1.6 }} transition={{ duration: 1.2 }} className="second-mobile-title center mt-2 mt-sm-5">
                            <p className="text-center second-mobile-title-content">Sites vitrines, sites e-commerce, solutions et applications web...</p>
                        </motion.div>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-5" xs={6}>
                        <animated.div style={{ transform: props.xy.interpolate(trans) }} className="logo-mobile-desktop card5">
                            <img className="header-desktop-img w-100" src={logo_1} alt="Projets web"/>
                        </animated.div>
                    </Col>
                    <Col className="mt-3 ml-3" xs={4}>
                        <animated.div style={{ transform: props.xy.interpolate(trans1) }} className="logo-header-desktop card6">
                            <img className="header-desktop-img w-100" src={logo_4} alt="Projets web"/>
                        </animated.div>
                    </Col>
                </Row>
            </header>
        </>
    );
};

export default HeaderMobile;

import React, {Component} from 'react';
import HeaderMobile from "../Header/HeaderMobile/HeaderMobile";
import HeaderDesktop from "../Header/HeaderDesktop/HeaderDesktop";
import FirstSectionMobile from "../FirstSection/FirstSectionMobile/FirstSectionMobile";
import FirstSectionDesktop from "../FirstSection/FirstSectionDesktop/FirstSectionDesktop";
import SecondSectionMobile from "../SecondSection/SecondSectionMobile/SecondSectionMobile";
import SecondSectionDesktop from "../SecondSection/SecondSectionDesktop/SecondSectionDesktop";
import ContactMobile from "../Contact/ContactMobile/ContactMobile";
import ContactDesktop from "../Contact/ContactDesktop/ContactDesktop";
import ThirdSectionMobile from "../ThirdSection/ThirdSectionMobile/ThirdSectionMobile";
import ThirdSectionDesktop from "../ThirdSection/ThirdSectionDesktop/ThirdSectionDesktop";
import SeparatorSection from "../SeparatorSection/SeparatorSection";
import Footer from "../Footer/Footer";

class HubPage extends Component {
    render() {
        const isMobile = window.innerWidth <= 768;
        return (
            <>
                { isMobile ? <HeaderMobile /> : <HeaderDesktop /> }
                { isMobile ? <FirstSectionMobile /> : <FirstSectionDesktop /> }
                { isMobile ? <SecondSectionMobile /> : <SecondSectionDesktop /> }
                <SeparatorSection />
                { isMobile ? <ThirdSectionMobile /> : <ThirdSectionDesktop /> }
                { isMobile ? <ContactMobile /> : <ContactDesktop /> }
                <Footer />
            </>
        );
    }
}

export default HubPage;
import React from 'react';
import {Row, Col} from "react-bootstrap";


import './ThirdSectionMobile.css';
import main_restaurant from "../../../img/logo/main_restaurant.jpg";
import EcommerceGalleryMobile from '../../EcommerceGallery/EcommerceGalleryMobile/EcommerceGalleryMobile';

const ThirdSectionMobile = () => {

    return (
        <>
            <div className="container-fluid mt-3 mb-3">
                <Row>
                    <Col className="center" xs={12}>
                        <img className="img-fluid w-75" alt="restaurant" src={main_restaurant} />
                    </Col>
                </Row>
                <Row className="">
                    <Col className="center mt-3 mb-3" xs={12}>
                        <h1 className="h2 text-center">Les projets de site<br /> e-commerce</h1>
                    </Col>
                    <Col className="gallery-mobile-section" xs={12}>
                        <EcommerceGalleryMobile />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ThirdSectionMobile;
import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import ItemsCarousel from 'react-items-carousel';

import './EcommerceGalleryMobile.css';


import left_chevron from '../../../img/icones/left-arrow.svg';
import right_chevron from '../../../img/icones/right-arrow.svg';
import V2_logo_pizza from "../../../img/images/logo-pizzav2.svg";
import logo_euratech from "../../../img/images/commerce-demo.svg";


const RestaurantGalleryMobile = () => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 30;
    const isMobile = window.innerWidth <= 480;

    return (
        <>
            <Row className="container-fluid p-0 mt-2 mb-5">
                <Col style={{ padding: `0 ${chevronWidth}px` }}>
                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={2}
                        gutter={20}
                        leftChevron={<button className="btn-arrow"><img src={left_chevron} className="w-100" alt="flèche de déplacement vers la gauche"/></button>}
                        rightChevron={<button className="btn-arrow"><img src={right_chevron} className="w-100" alt="flèche de déplacement vers la droite"/></button>}
                        outsideChevron
                        chevronWidth={chevronWidth}
                    >
                        {
                            isMobile ?
                                (
                                    <div className="center" style={{ height: 80, background: '#FFF' }}>
                                        <a className="center" href="https://v2-pizza.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                                            <img className="img-fluid mb-2 w-50" src={V2_logo_pizza} alt="lien pour site e-commerce thème pizzéria" />
                                        </a>
                                    </div>
                                ):(
                                    <div className="center" style={{ height: 150, background: '#FFF' }}>
                                        <a className="center" href="https://v2-pizza.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                                            <img className="img-fluid mb-3 w-50" src={V2_logo_pizza} alt="lien pour site e-commerce thème pizzéria" />
                                        </a>
                                    </div>
                                )
                        }
                        {
                            isMobile ?
                                (
                                    <div className="center" style={{ height: 80, background: '#FFF' }}>
                                        <a className="center" href="https://euratech-demo.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                                            <img className="img-fluid w-75" src={logo_euratech} alt="lien pour site e-commerce de reservation" />
                                        </a>
                                    </div>
                                ):(
                                    <div className="center" style={{ height: 150, background: '#FFF' }}>
                                        <a className="center" href="https://euratech-demo.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                                            <img className="img-fluid w-75" src={logo_euratech} alt="lien pour site e-commerce de reservation" />
                                        </a>
                                    </div>
                                )
                        }
                    </ItemsCarousel>
                </Col>
                <Col className="mobile-vitrine-presentation mt-5" xs={12}>
                    <p className="text-center mb-2 h6">
                        Il permet d'administrer le contenu comme les services et/ou les produits proposés par catégories, la bannière d'accueil mais aussi la gestion des clients,
                        des modes de livraison et leur prix, des commandes et des livraisons. Pour l'utilisateur présence d'un espace membre avec commande et paiement en ligne
                        sécurisé ainsi que la gestion de son compte. S'ajoutent un formulaire de contact relié à une boîte mail, la gestion des envois d'emails à vos clients avec
                        une mise en page et la possibilité de créer une newsletter.<br/>
                        Un véritable outil de communication et de gestion sécurisé<br/>
                    </p>
                </Col>
            </Row>
        </>
    );
};

export default RestaurantGalleryMobile;

import React from 'react';
import {Col, Row} from "react-bootstrap";
import { Carousel } from 'react-responsive-carousel';

import './SeparatorSection.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const SeparatorSection = () => {

    const isMobile = window.innerWidth <= 480;

    return (
        <>
            <Row className="center separator-section">
                <Col xs={12} md={10} xl={8} className="mt-3 mt-md-0 p-md-5 quote-section">
                    <h3 className="text-center text-white mb-4 font-weight-bolder">Ils m'ont fait confiance...</h3>
                    <Carousel
                        interval={13000}
                        transitionTime={1000}
                        infiniteLoop={true}
                        autoPlay={true}
                        stopOnHover={true}
                        showIndicators={false}
                        showThumbs={false}
                        showStatus={false}
                        showArrows={false}
                        className="text-white quote-carousel mt-3 mb-3"
                    >
                        <div>
                            <h4 className="font-weight-bolder">Arnaud Méliande <br/><small><em>Président NLFL</em></small></h4>
                            <p className="recommandation text-center h5">
                                " Lors d'une mission temporaire de 2 mois, j'ai eu l'occasion de travailler avec Julien pour le développement de ma plateforme en REACT.
                                Julien est une personne sérieuse et de bonne volonté. Force de proposition, il nous a vraiment bien aidés en complétant une par une les missions qui
                                lui ont été confiées. Au délà de ça, Julien est une personne très serviable, sympatique et bienveillante. Je le recommande donc sans hésitations. "
                            </p>
                        </div>
                        <div>
                            <h4 className="font-weight-bolder">Arnaud Boucher <br/><small><em>Dirigeant AB Ramonage</em></small></h4>
                            <p className="recommandation text-center h5">
                                " J'avais un besoin rapide de mise en place d'un site afin d'accélérer la croissance de ma société, Julien a su répondre à mes attentes tant en termes de
                                délais que de compétences. Toujours pédagogue et prêt à utiliser ses compétences commerciales pour m'aider dans la réalisation de ce projet. Je recommande
                                vivement ce développeur passionné et efficace. "
                            </p>
                        </div>
                    </Carousel>
                </Col>
                <Col xs={12} md={10} xl={8}>
                    {
                        isMobile ?
                            <p className="p-3 separator-content font-weight-bolder text-center">
                                N'hésitez pas à me contacter au <a className="text-white font-weight-bolder" title="lien pour appeler e-kigaï-dev" href="tel:+33650664338"><strong>0650664338</strong></a> ou par le biais du formulaire de contact en bas de page, je suis toujours à l'écoute des commerçants
                                souhaitant se numériser. Les solutions que je propose sont durables, génératrices de CA et coûtent très peu de frais afin d'être maintenues en ligne.
                                Je prends en charge la partie mise en ligne et aide les commerçants à se faire une image sur internet et gagner en visibilité.
                                Les aides gouvernementales peuvent permettre d'envisager sa digitalisation plus confortablement et ainsi obtenir une solution durable en cas de nouvelle fermeture
                                administrative mais aussi toucher de nouveaux clients
                            </p> :
                            <p className="p-3 separator-content font-weight-bolder text-center">
                                N'hésitez pas à me contacter au <small>0650664338</small> ou par le biais du formulaire de contact en bas de page, je suis toujours à l'écoute des commerçants
                                souhaitant se numériser. Les solutions que je propose sont durables, génératrices de CA et coûtent très peu de frais afin d'être maintenues en ligne.
                                Je prends en charge la partie mise en ligne et aide les commerçants à se faire une image sur internet et gagner en visibilité.
                                Les aides gouvernementales peuvent permettre d'envisager sa digitalisation plus confortablement et ainsi obtenir une solution durable en cas de nouvelle fermeture
                                administrative mais aussi toucher de nouveaux clients
                            </p>
                    }

                </Col>
            </Row>
        </>
    );
};

export default SeparatorSection;

import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";

import './ThirdSectionDesktop.css';

import EcommerceGalleryDesktop from '../../EcommerceGallery/EcommerceGalleryDesktop/EcommerceGalleryDesktop';

import commerce from '../../../img/images/e-commerce-2.svg';
import croissance from "../../../img/images/croissance.svg";

class ThirdSectionDesktop extends Component {
    render() {
        return (
            <>
                <div className="desktop-second-section">
                    <Row>
                        <Col className="center" xs={12}>
                            <img className="img-fluid w-50" alt="restaurant" src={commerce} />
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="center mt-3 mb-5" xs={12}>
                            <h1>Les projets de site e-commerce</h1>
                        </Col>
                        <Col className="commerce-gallery-desktop-section container" xs={8}>
                            <EcommerceGalleryDesktop />
                        </Col>
                    </Row>
                    <Row className="bottom-pics">
                        <Col className="center" xs={12}>
                            <img className="img-fluid w-50" alt="table de restaurant" src={croissance} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default ThirdSectionDesktop;
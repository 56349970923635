import React from 'react';
import {animated, useSpring} from "react-spring";
import logo_pizza from "../../../img/images/logo-pizza.svg";
import ReactTooltip from "react-tooltip";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x/2 - window.innerWidth / 3) / 20, 1.1];
const trans1 = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const PizzaCard = () => {

    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));

    return (
        <>
            <a data-tip data-arrow-color="#5C88E5" data-for='pizza'  href="https://v1-pizza.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                <animated.div
                    className="card-second-section-desktop-1"
                    onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
                    onMouseLeave={() => set({xys: [0, 0, 1]})}
                    style={{
                        transform: props.xys.interpolate(trans1),
                        backgroundImage: `url(${logo_pizza})`,
                        backgroundSize: "80%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "white"
                    }}
                />
            </a>
            <ReactTooltip delayShow={300} className="tooltip-desk-item" id="pizza" aria-haspopup='true' place="right" data-multiline={true} role='example'>
                <p className="text-center h4">
                    <span>Site vitrine type pizzeria</span><br/>
                </p>
                <p className="h6">Site permettant d'afficher le contenu souhaité, comme la carte du restaurant avec filtres de recherche, les horaires, une présentation de l'entreprise
                    mais aussi retrouver vos publications venant de réseaux sociaux. Vous bénéficiez également d'un formulaire de contact sécurisé et relié à votre
                    boîte mail, vous permettant de rester en contact avec vos clients ou d'éventuels partenaires. Deux versions distinctes mobile et ordinateur, la version
                    mobile possèdant un bouton sur l'accueil permettant de vous appeler directement pour commander.<br/></p>
            </ReactTooltip>
        </>
    );
};

export default PizzaCard;

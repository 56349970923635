import React from 'react';
import {Row, Col} from "react-bootstrap";
import {isTablet} from 'react-device-detect';


import './RestaurantGalleryDesktop.css';

import FriterieCard from "./FriterieCard";
import PizzaCard from "./PizzaCard";
import RamonageCard from './RamonageCard';
import KebabCard from "./KebabCard";

const RestaurantGalleryDesktop = () => {

    return (
        <>
            <div>
                {
                    isTablet &&
                    <Row>
                        <Col className="mobile-vitrine-presentation mt-5" xs={12}>
                            <p className="text-center mb-2 h6">
                                Site permettant d'afficher le contenu souhaité, comme la carte du restaurant avec filtres de recherche, les horaires, une présentation de l'entreprise
                                mais aussi retrouver vos publications venant de réseaux sociaux. Vous bénéficiez également d'un formulaire de contact sécurisé et relié à votre
                                boîte mail, vous permettant de rester en contact avec vos clients ou d'éventuels partenaires. Deux versions distinctes mobile et ordinateur, la version
                                mobile possèdant un bouton sur l'accueil permettant de vous appeler directement pour commander.<br/>
                            </p>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={12}>
                        <Row className="center">
                            <Col xs={5} xl={6} className="center mt-5">
                                <FriterieCard />
                            </Col>
                            <Col xs={5} xl={6} className="center mt-5">
                                <PizzaCard />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Row className="center">
                            <Col xs={5} xl={6} className="center mt-5">
                                <RamonageCard />
                            </Col>
                            <Col xs={5} xl={6} className="center mt-5">
                                <KebabCard />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default RestaurantGalleryDesktop;

import React from 'react';
import {animated, useSpring} from "react-spring";
import logo_euratech from "../../../img/images/commerce-demo.svg";
import ReactTooltip from "react-tooltip";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x/2 - window.innerWidth / 3) / 20, 1.1];
const trans4 = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const EuratechCard = () => {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));

    return (
        <>
            <a data-tip data-arrow-color="#5C88E5" data-for='euratech'  href="https://euratech-demo.xn--e-kiga-dev-c9a.fr/" rel="noopener noreferrer" target="_blank">
                <animated.div
                    className="card-second-section-desktop-3"
                    onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
                    onMouseLeave={() => set({xys: [0, 0, 1]})}
                    style={{
                        transform: props.xys.interpolate(trans4),
                        backgroundImage: `url(${logo_euratech})`,
                        backgroundSize: "80%",
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "white"
                    }}
                />
            </a>
            <ReactTooltip delayShow={300} className="tooltip-desk-item" id="euratech" aria-haspopup='true' place="bottom" data-multiline={true} role='example'>
                <p className="text-center h4">
                    <span>Site e-commerce type Livraison et Click and Collect</span><br/>
                </p>
                <p className="h6">Site de gestion de reservation à des ateliers numériques pour enfant. Il permet à l'administrateur de créer ses ateliers, gérer ses
                    campagnes de réservations, ses réservations, mais aussi d'avoir accès à un tableau de bord lui donnant accès à de nombreuses statistiques imprimables.
                    Pour l'utilisateur création de son espace membre avec reservation et inscriptions facilités, gestion de son compte.
                    Un véritable outil de travail, de communication et de gestion approfondie sécurisé<br/></p>
            </ReactTooltip>
        </>
    );
};

export default EuratechCard;

import React, {Component} from 'react';
import {Link} from "react-router-dom";

import './NotFoundPage.css';

class NotFoundPage extends Component {
    render() {
        return (
            <>
                <div className="not-found-section center row">
                    <div className="col-12 center">
                        <p className="h1"><strong>Oops... Vous semblez perdu</strong></p>
                    </div>
                    <Link to="/" className="btn btn-lg btn-light btn-back">Retour vers le site de démonstration</Link>
                </div>
            </>
        );
    }
}

export default NotFoundPage;
import React from 'react';

import './FirstSectionMobile.css';
import {Col, Row} from "react-bootstrap";
import {motion} from "framer-motion";



const FirstSectionMobile = () => {
    return (
        <>
            <div className="container-fluid first-section-mobile p-0">
                <Row>
                    <Col className="presentation-desktop-section mt-3 mb-5 center" xs={12}>
                        <div className="container">
                            <p className="presentation-mobile text-center center text-white">
                                Ces projets sont des versions de démonstration réalisées dans le but de proposer des solutions
                                web aux commerçants de ma région. Je prends en charge la conceptionet la réalisation de plateformes
                                regroupant les sites des commerçants. Je créé aussi leurs sites qu'ils soient sites vitrines
                                ou e-commerce, répondant donc à tous les budgets. Fort d'une expérience commerciale de quinze ans je souhaite la
                                mettre au service des commerçants et les aider à se faire une place sur internet.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="text-white center">
                    <Col className="center mobile-company-title" xs={12} >
                        <motion.div
                            animate={{
                                scale: [1, 1.35, 1.35, 1, 1],
                                rotate: [0, 0, 270, 270, 0],
                                borderRadius: ["20%", "20%", "50%", "50%", "20%"]
                            }}
                            transition={{
                                duration: 4,
                                ease: "easeInOut",
                                times: [0, 0.2, 0.5, 0.8, 1],
                                loop: Infinity,
                                repeatDelay: 1
                            }}
                        >
                            <h5 className="font-weight-bold">Site vitrine</h5>
                        </motion.div>
                    </Col>
                    <Col className="list-mobile-content text-center pl-0" xs={10} md={8}>
                        <ul className="list-skill pl-0">
                            <li>- Il présente ses services</li>
                            <li className="mt-2">- Il comporte un formulaire de contact</li>
                            <li className="mt-2">- Sur mobile il est possible d'ajouter un bouton lançant un appel pour commander</li>
                            <li className="mt-2">- Il représente un petit budget et un temps de réalisation rapide</li>
                        </ul>
                    </Col>
                </Row>
                <Row className="text-white center mt-5">
                    <Col className="center mobile-company-title mb-3" xs={12}>
                        <motion.div
                            animate={{
                                scale: [1, 1.35, 1.35, 1, 1],
                                rotate: [0, 0, 270, 270, 0],
                                borderRadius: ["20%", "20%", "50%", "50%", "20%"]
                            }}
                            transition={{
                                duration: 4,
                                ease: "easeInOut",
                                times: [0, 0.2, 0.5, 0.8, 1],
                                loop: Infinity,
                                repeatDelay: 1
                            }}
                        >
                            <h5 className="font-weight-bold">Site e-commerce</h5>
                        </motion.div>
                    </Col>
                    <Col className="list-mobile-content text-center pl-0" xs={10} md={8}>
                        <ul className="list-skill pl-0">
                            <li>- Permet au client de commander et payer</li>
                            <li className="mt-2">- Contrôler le contenu du site (produits proposés, informations)</li>
                            <li className="mt-4">- Améliorer sa relation client ainsi que son CA</li>
                            <li className="mt-3">- Un réél outil de travail au quotidien, votre boutique 24h sur 24, 7 jours sur 7 ouverte</li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default FirstSectionMobile;
